/*************** Reset **************/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{
margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
display:block;}
body{line-height:1;}
ol,ul{list-style:none;}
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
div {box-sizing:border-box;}
input, select, textarea   {
  box-sizing: content-box;
}
.spider .dodo:not(:first-child), 
.spider .eveil:not(:first-child),
.spider .peur:not(:first-child),
.spider .reveil:not(:first-child)
{
  position:absolute;
  top:3em;left:0;
}


.spider .dodo, 
.spider .eveil,
.spider .peur,
.spider .reveil
{
  opacity:0;
}
.dodo-1, .dodo-2, .dodo-3 {
  opacity:0
}
.spider:not(:hover) .dodo-1 {
  animation:un 5s 10s infinite;
}
.spider:not(:hover) .dodo-2 {
  animation:deux 5s 10s infinite;
}
.spider:not(:hover) .dodo-3 {
  animation:trois 5s 10s infinite;
}

.spider:not(:hover) .eveil {
  animation:appear 0s 3s forwards, disappear 0s 10s forwards;
}
.spider:not(:hover) .reveil {
  opacity:1;
  animation:disappear 0s 3s forwards;
}
.spider:not(:hover) .dodo {
  animation: appear 0s 10s forwards ;
}
.spider:hover .peur {
  opacity:1;
}
.spider .yeux {
  display:block; top:-1em; position:relative;height:0;
}

.spider .box {
  transform: translateY(-100%);
  animation:enter .8s 2s forwards;
  position:relative;
}
.spider{
  overflow:hidden;
/*  min-height:20rem;*/
}
.spider .wire{
  transform: rotate(0);
  transform-origin: 50% 0%;
  transition:transform .6s;
}
.spider:hover .wire {
  animation:remou 3.6s;
}
i {
  font-style:normal;
  text-decoration:none;
  display:inline;
}
/*********** Keyframes **********/
@keyframes un {
  from   {opacity:1;}
  74%   {opacity:1;}
  80%   {opacity:0;}
  to   {opacity:0;}
}
@keyframes deux {
  from   {opacity:0;}
  26%  {opacity:0;}
  27%  {opacity:1;}
  74%  {opacity:1;}
  80%   {opacity:0;}
  to   {opacity:0;}
}
@keyframes trois {
  from   {opacity:0;}
  53%  {opacity:0;}
  54%  {opacity:1;}
  77%   {opacity:1;}
  80%   {opacity:0;}
  to   {opacity:0;}
}
@keyframes appear {
  to   {opacity:1;}
}
@keyframes disappear {
  to   {opacity:0;}
}
@keyframes enter {
  from {transform: translateY(-100%);}
  to   {transform: translateY(0%);}
}
@keyframes remou {
  from { transform:rotate(0deg  );}
  25%  { transform:rotate(15deg);}
  50%  { transform:rotate(-15deg);}
  75%  { transform:rotate(15deg);}
  to   { transform:rotate(0);}
}

/*parent*/
/*perspective:465px;*/
/**/
/*child*/
/*transform: rotateY(330deg) rotateX(9deg);*/

header a:hover,header a:focus,header a {
/*display:block;*/
outline:none;text-decoration:none;color:inherit;
}
header pre {
  pointer-events: none;
  text-align:left;
  color:#444;
  font-weight:900;
/*  text-align:center;*/
}
header svg {padding:1rem; transform:scale(2);}
header h2 {font-weight:normal;font-family:monospace;opacity:0;animation:1s appear 3s forwards;}
nav label[for$="-tg"]{
  position: relative;
  font-weight: 800;
  font-size: 1.4rem;
  font-stretch: ultra-condensed;
  color: #333;
  left:-1ch;
}
nav aside {
  text-align:right;padding-top:1rem;
}
nav .tgnav {
/*  max-width:80ch;*/
  border-top: 1px solid #3c3c3c;
}
#menu{
background: white;
border: 1px dashed #929292;
border-radius: 6px;
padding:1ch;
}
nav .box {
/*  border: 1px dashed #929292;*/
  border-top:none;
  padding: 1rem;
  line-height:2rem;
/*  background: #FFF;*/
}
nav .projet {
  padding:0.5rem;
}
header h1 {
  position:fixed;
  top:120vh;
  color:tranparent;
  height:0;
}
header{
  /*  min-width:63ch;*/
  margin:auto;
  display:inline-block;
  vertical-align:top;
}
#title{
  font-family:monospace;
  width:63ch;
  margin:auto;
  font-size:.9em
}

@font-face {
  font-family: 'fontregular';
  src: url('../font/OpenDyslexic-Regular.eot');
  src: url('../font/OpenDyslexic-Regular.woff') format('woff'),
  url('../font/OpenDyslexic-Regular.ttf') format('truetype'),
  url('../font/OpenDyslexic-Regular.svg#BebasNeue') format('svg'),
  url('../font/OpenDyslexic-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

/**/


/*************** Containers **************/
.container {
  position:relative; 
  width:100%;
  min-width:320px;
  max-width:160ch;
  margin:auto;
  padding-top:1rem;
  text-align:center;
}

.twopanes {
  text-align:center;
  width:100%;
  max-width:160ch;
  margin:auto;
}

.twopanes > .left{
  /*text-align:left;*/
  vertical-align:top;
  padding: .3rem;
}
.twopanes > .right{
  padding: .3rem;
}

.aleft{
  text-align:left;
  margin:auto;
  vertical-align:top;
}

/***
 * ************ Responsive part ***********
 * >320px : mobile small 2:3 & 3:5 
 * >540px : mobile 9:16
 * >768px : tablet small  3:5      container 85%
 * >992px : medium screen 9:16     container 70%
 * >1200px : large screen
 * ***/
.container {
  margin:auto;
  max-width:1280px;
  box-sizing:border-box;
  position:relative;
}


@media all and (min-width:1024px) {
  .twopanes > .left{
    display:inline-block;
    width:50%;
  }
  .twopanes > .right{
    display:inline-block;
    width:50%;
  }
}


nav {
  margin:auto;
  width:100%;
  /*  display:inline-block;*/
  position:relative;
  z-index:9;
  text-align:left;
  vertical-align:top;
  padding:0;
}
@media all and (min-width:768px and max-width:1024px) {
  header  {
    font-size:.975vw;
  }
}
@media all and ( max-width:768px) {
  header  {
    font-size:2.3vw;
  }
  .container {
    width:100%;
    min-width:100%;
  }
}
:not(:checked) + .ab-switch :nth-child(1),.ab-switch :nth-child(1),:checked + .ab-switch :nth-child(2),.ab-switch.active :nth-child(2) {
display:initial;
}

:not(:checked) + .ab-switch :nth-child(2),.ab-switch :nth-child(2),:checked + .ab-switch :nth-child(1),.ab-switch.active :nth-child(1) {
display:none;
}


input[id$="-tg"],
input[id$="-utg"]{
  opacity:0; position:absolute;z-index:-1;
}
.tg:not(.or), .tgpart {
  display:none;
}
input[id$="-tg"]:checked ~ .tgpart {
  display:block !important;
}
input[id$="-tg"]:checked ~ .tgnav,
.tgnav:focus {
  max-height:64rem;
  transition: max-height .6s cubic-bezier(.6,.04,.98,.34);
}

input[id$="-utg"]:checked + .tgscroll, 
input[id$="-tg"]:checked ~ .tgscroll{
  max-height:64rem;
  transition: max-height .6s cubic-bezier(.6,.04,.98,.34);
}

input[id$="-utg"]:checked + .tgscroll.hasimg, 
input[id$="-tg"]:checked ~ .tgscroll.hasimg{
  max-height:384rem;
  transition: max-height .6s cubic-bezier(.6,.04,.98,.34);
}

input[id$="-tg"]:not(:checked) ~ .tgnav a:focus {
  position:absolute;
  background:white;
  z-index:3;
  /*  top:50vh;*/
/*  left:50vw;*/
}
.tgscroll, .tgnav, .tgscrollb {
  outline:none;
  transition: max-height 0.6s cubic-bezier(0,1,0,1);
  max-height:0;
  overflow-y:hidden;
}
input[id$="art-tg"]:not(:checked) + p + label,
input[id$="art-tg"]:checked + p + label + label {
  display:none;
} 


label[for$="-tg"].sharp:before ,
label[for$="-tg"].sharp:after 
{ 
  content:"#";
  width:1ch;
  color:transparent;
}


/*- Triggers settings (menu hamburger...) **/
label[for="submit"],
label[for$="-utg"],
label[for$="-tg"] {
  cursor:pointer;
}

label[for$="-tg"]:hover:before, 
label[for$="-tg"]:hover:after, 
label[for$="-tg"]:focus:before, 
label[for$="-tg"]:focus:after, 
input[id$="-tg"]:focus ~ label[for$="-tg"]:before, 
input[id$="-tg"]:focus ~ label[for$="-tg"]:after 
{
  color:#FF4279;
}
#links ul {
  text-align:center;
}

/* HYPERLINKS */
:active{
  outline:none;
}
a {
  color:#8268e5;
  padding:0.5rem;
}
a:hover, a:focus {
  color:#000;
  outline:1px dotted #FF4279;
}
nav i {
  color:#433578;
}
a:visited {
  color:#433578;
}
a:active {
  color:#42FFC8;
}
nav a:visited {
  text-decoration:none;
}
a[class^="icon-"], #map-info a, .source a {
  text-decoration:none;
  color:inherit;
}
/* /HYPERLINKS */

.grid {
  height:100%;
  left:0;
  opacity:.1;
  overflow:hidden;
  pointer-events:none;
  position:absolute;
  top:0;
  width:100vw;
  z-index:0;
}

.grid::after {
  background:linear-gradient(tobottom,transparent 50%,rgba(0,0,0,0.1) 51%);
  background-size:100% 4px;
}

.grid::before {
  background:linear-gradient(toright,transparent 50%,rgba(0,0,0,0.1) 51%);
  background-size:4px 100%;
}

.grid::before,.grid::after {
  bottom:0;
  content:'';
  display:block;
  left:0;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
}
.toolbar {
  padding: .8rem;
  position:absolute;
  right:0;
  top:0;
  /*border-left: 1px dashed #929292;*/
  /*border-right: 1px dashed #929292;*/
  /*border-top: 1ch solid #494949;*/
}

.subfooter:before {
  content:'';
  display:block;
  position:absolute;
  border:1px solid #777;
  border-botom:none;
  box-sizing: border-box;
  height:100%;
  opacity:.9;
}
.subfooter:before {
  top:4px;
  z-index:-1;
  width:101%;
  left:-.5%;
}
.subfooter aside {
  position:absolute;
  display:block;
  border:1px solid rgba(0,0,0,.88);
  top:-4px;
  right:1%;
  z-index:-1;
  padding:0 0.5em 2em;
  text-align:right;
  transition:all .6s;
  color:black;
  background:#fff;
  box-sizing:border-box;
}
.subfooter:hover aside {
  top:-2.2em;
}
.subfooter:after {
  content:'';
  position:absolute;
  width:100%;
  height:100%;
  z-index:1;
  background: forestgreen;
  top:0;left:0;
}
.subfooter {
  /*border-top:1px solid #000;*/
  bottom:0;
  color:#FFF;
  padding:0;
  position:fixed;
  width: 92%;
  z-index:9;
  left: 50%;
  transform: translate(-50%);
  opacity:0;
  animation:.6s appear 5s forwards;
}

.subfooter a {
  color:inherit;
  display:inline-block;
  font-size:1.2rem;
  text-decoration:none;
}

.subfooter a:hover {
  color:#000;
  /* text-shadow:none; */
}

.subfooter nav {
  display:block;
  text-align:center;
  /* text-shadow:0 2px 0 #b92f57; */
}

.subfooter nav li {
  height:3rem;
  overflow:hidden;
}

b,#links li,.subfooter li {
  display:inline-block;
  vertical-align:top;
}

body {
  color:#000;
  background:#F9F9F9;
  font-family:Arial, Helvetica, sans-serif;
  font-size:1.1rem;
  overflow-x:hidden;
  padding-bottom:20rem;
  position:relative;
}

h1 {
  color:#002722;
  font-size:1.5rem;
  font-weight:900;
}

h2 {
  color:#002;
  font-size:1.2rem;
  font-weight:900;
}

h3 {
  color:#00562F;
  font-size:1.1rem;
  font-weight:600;
}

html {
  font-size:83.300003%;
}

label .req {
  color:#FF4279;
}

nav li {
  margin:.8rem 4px 0;
  min-height:1.3rem;
}

noscript {
  padding:.5rem;
}

.error {
  background:#ffd8e3;
  border:1px dotted red;
  padding:.5rem;
}

pre:focus,section:focus {
  outline:none;
}

pre:not(.msg) {
  -moz-user-focus:ignore;
  cursor:default;
  font-family:monospace!important;
}

pre:not(.msg),label {
  -moz-user-select:0;
  -ms-user-select:none;
  -webkit-user-select:none;
  user-select:none;
}

/** style balise article **/
article {
  /* background:#FFF none repeat scroll 0 0; */

  border-left:1px dashed #929292;
  border-right:1px dashed #929292;
  box-sizing:border-box;
  position:relative;
  z-index:3;
}

article .categ {
  left:0;
}

article .publie {
  right:0;
}

article .publie,article .categ {
  color:#CCC;
  position:absolute;
  top:-2rem;
}

article b.emo {
  background:#f7f7f7;
  font-weight:600;
  padding:.2rem;
}

article h1 {
  padding-bottom:1.4rem;
}

article h1 + main > h2:first-child {
  padding-top:0;
}

article h2 {
  color: #222;
  padding: .4rem;
  text-shadow: 0 1ch 1ch yellow;
}

article h3 {
  padding:.2rem;
}

article h3 + p,article h3 + .tgscroll p {
  padding-top:1rem;
}

article img {
  max-width:100%;
}

article li {
  margin-left:1rem;
  padding-left:1px;
}

article li,article p {
  font-weight:300;
}

article main {
  line-height:1.8em;
}

article p {
  padding-bottom:1rem;
}

article strong {
  border:1px solid #f4f4f4;
  font-weight:600;
  padding:.3rem;
}

article ul, ul {
  list-style:square;
}

article:after {
  bottom:-.5px;
}

article:before {
  top:-.5px;
}

article:before,article:after {
  border-top:1px dotted #fff;
  content:'';
  display:block;
  position:absolute;
  right:0;
  width:100%;
}

input ~ label[for$=art-tg] {
  display:block;
  text-align:right;
}
#mentions h1,#cgu h1 {
text-align:center;
}

.articles{
margin:1rem 0;
padding:4rem 0;
position:relative;
}

.articles article , .contentinfo article{
/*  display:inline-block;*/
/*  text-align:left;*/
/*  max-width:80ch;*/
  width:100%;
  padding:0.8rem;
/*  margin:1rem 0 0 0;*/
}
.left {
right:100%;
}


.right {
left:100%;
}

.sidething {
height:100%;
overflow-y:hidden;
position:absolute;
top:0;
}


.portfolio {
/*background:#000;*/
margin:1rem 0;
padding:1rem 0;
position:relative;
text-align:center;
opacity:0;
animation:1s appear 4s forwards;
}

.portfolio article {
padding: .4em;
margin: .2em;
  width:21ch;
  display:inline-block;
vertical-align:top;
text-align:left;
position:relative;
overflow:hidden;
}
.portfolio .overlay {
position:absolute;
width:100%;
height:100%;
left:0;
top:90%;
background:#fff;
transition:all .4s;
}
.portfolio article:hover .overlay, .portfolio article:focus .overlay {
top:0;
}
/** 
 *  demos
 * **/
.code {
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  font-family:monospace;
  font-size:10px;
}

.code > * {
  display:block;
  line-height:1em;
  padding:.6em;
  margin:.2em;
  margin-top:1.2em;
  position:relative;
  border:1px solid black;
  flex-grow:3;
/*  width:100%;*/
}
.code > .result {
  width:100%;
}

.look{
  color:#c0c0c0;
}
.code > * > span:first-child {
  position:absolute;
  top:-1rem;
  right:0;
  color:#333;
}
code > div {
  width:100%;
}
p i {
  font-style:italic;
}
code i {
 color:darkmagenta;
}
code b {
 color:blue;
}
code div {
  padding:0 0 0 2ch;
}
#contact {
  z-index: 9;
  position:relative;
}

#contact form {
  background:#fff;
  padding:6em 1em 0 6em;
}

#contact legend {
  font-size:3em;
  padding: 0;
  color: #444;
  transform: rotate(-90deg);
  position: absolute;
  left: 0;
  top: 60%;
  transform-origin: 0 0;
}

#contact form fieldset:last-of-type {
}

#email, #message, #subject {
  font: 1em sans-serif;
  width: 100%;
  max-width: 97vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 10px solid transparent;
  color: #444;
  font-size: 1rem;
  padding: 0;
  line-height: 2rem;
  display: block;
  border-bottom-color: #DDD;
  box-shadow: 0px 3px #444;
}

input[type="email"]:hover,
input[type="search"]:hover,
input[type="text"]:hover,
input[type="url"]:hover,
textarea:hover, select:hover {
  border-color: #eee;
}

input[type="email"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
textarea:focus, select:focus {
  background-color: #eee;
  outline: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
  font-weight: 400;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #444;
  opacity: 1;
  font-weight: 400;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #444;
  opacity: 1;
  font-weight: 400;
}

input::placeholder,
textarea::placeholder {
  color: #c7c7c7;
  opacity: 1;
  font-weight: 400;
}
#contactme {
  position: fixed;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
#contactme a {
  color:#000;
  text-decoration:none;
  display:inline-block;
}
#contact fieldset {
  text-align:left;
  padding:1ch 0.5ch;
}

.msgbox {
  width:50%;
  left:25%;
  position:relative;
  text-align:left;
  padding:1em;
  background:#F4F4F4;
  border-top: 2px solid #000;
}

#sendmsg  {
  border: 1px dashed;
  background: lavender;
  margin: 2px 0 1rem;
  cursor:pointer;
}
#sendmsg:hover, #sendmsg:focus{
  background:palegreen;
  border: 1px solid transparent;
}
fieldset:not(:valid) ~ #sendmsg {
  color: #ccc;
  background: none; 
}

fieldset:not(:valid) ~ #sendmsg:hover, fieldset:not(:valid) ~ #sendmsg:hover{
  background: crimson;
  color:#fff;
}

#sendmsg:active {
  border:1px solid #000;
}
fieldset:not(:valid) ~ div #viator {
  visibility:hidden;
}
#sendmsg:not(:active) + div #viator {
  animation:deliver 2s ;
}
#viator {
  left:0;
  position:relative;
}
@keyframes deliver{
  0% {
    left:0;
  }
  19% {
    left:0ch;
  }
  20% {
    left:5ch;
  }
  39% {
    left:5ch;
  }
  40% {
    left:10ch;
  }
  59% {
    left:10ch;
  }
  60% {
    left:15ch;
  }
  79% {
    left:15ch;
  }
  80% {
    left:20ch;
  }
  to {
    left:80ch;
  }
}


#dys-tg:checked ~ div {
  font-family: fontregular, arial, sans-serif;
}
#dys-tg:checked ~ div article * {
  font-family: fontregular, arial, sans-serif;
}

#dys-tg:checked ~ div label[for="dys-tg"] {
  color:#333;
}
#dys-tg:checked ~ div label[for="dys-tg"] em {
  transform:rotate(0deg);
}
label[for="dys-tg"] {
  color:#F4F4F4;
  font-size:0.8rem;
}
label[for="dys-tg"] em {
  color:#333;
  font-size:1.2em;
  position:relative;
  display:inline-block;
  transform:rotate(-12deg);
}
.asciibox b:before {
  content:' ';
  left:-100%;
  top:-55%;
  padding:135% 100%;
  position:absolute;
  background:#000;
  z-index: -1; 
}
.asciibox b {
  color:transparent;
  background: white;
  position:relative;
}
header .asciibox {
  position:relative;
  animation:.6s entlef .6s;
}
#links ul {
  text-align:center;
}

/* HYPERLINKS */
:active{
  outline:none;
}
a {
  color:#8268e5;
  padding:0.5rem;
}
a:hover, a:focus {
  color:#000;
  outline:1px dotted #FF4279;
}
nav i {
  color:#433578;
}
a:visited {
  color:#433578;
}
a:active {
  color:#42FFC8;
}
nav a:visited {
  text-decoration:none;
}
a[class^="icon-"], #map-info a, .source a {
  text-decoration:none;
  color:inherit;
}
/* /HYPERLINKS */

.grid {
  height:100%;
  left:0;
  opacity:.1;
  overflow:hidden;
  pointer-events:none;
  position:absolute;
  top:0;
  width:100vw;
  z-index:0;
}

.grid::after {
  background:linear-gradient(tobottom,transparent 50%,rgba(0,0,0,0.1) 51%);
  background-size:100% 4px;
}

.grid::before {
  background:linear-gradient(toright,transparent 50%,rgba(0,0,0,0.1) 51%);
  background-size:4px 100%;
}

.grid::before,.grid::after {
  bottom:0;
  content:'';
  display:block;
  left:0;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
}
.toolbar {
  padding: .8rem;
  position:absolute;
  right:0;
  top:0;
  /*border-left: 1px dashed #929292;*/
  /*border-right: 1px dashed #929292;*/
  /*border-top: 1ch solid #494949;*/
}

.subfooter:before {
  content:'';
  display:block;
  position:absolute;
  border:1px solid #777;
  border-botom:none;
  box-sizing: border-box;
  height:100%;
  opacity:.9;
}
.subfooter:before {
  top:4px;
  z-index:-1;
  width:101%;
  left:-.5%;
}
.subfooter aside {
  position:absolute;
  display:block;
  border:1px solid rgba(0,0,0,.88);
  top:-4px;
  right:1%;
  z-index:-1;
  padding:0 0.5em 2em;
  text-align:right;
  transition:all .6s;
  color:black;
  background:#fff;
  box-sizing:border-box;
}
.subfooter:hover aside {
  top:-2.2em;
}
.subfooter:after {
  content:'';
  position:absolute;
  width:100%;
  height:100%;
  z-index:1;
  background: forestgreen;
  top:0;left:0;
}
.subfooter {
  /*border-top:1px solid #000;*/
  bottom:0;
  color:#FFF;
  padding:0;
  position:fixed;
  width: 92%;
  z-index:9;
  left: 50%;
  transform: translate(-50%);
  opacity:0;
  animation:.6s appear 5s forwards;
}

.subfooter a {
  color:inherit;
  display:inline-block;
  font-size:1.2rem;
  text-decoration:none;
}

.subfooter a:hover {
  color:#000;
  /* text-shadow:none; */
}

.subfooter nav {
  display:block;
  text-align:center;
  /* text-shadow:0 2px 0 #b92f57; */
}

.subfooter nav li {
  height:3rem;
  overflow:hidden;
}

b,#links li,.subfooter li {
  display:inline-block;
  vertical-align:top;
}

body {
  color:#000;
  background:#F9F9F9;
  font-family:Arial, Helvetica, sans-serif;
  font-size:1.1rem;
  overflow-x:hidden;
  padding-bottom:20rem;
  position:relative;
}

h1 {
  color:#002722;
  font-size:1.5rem;
  font-weight:900;
}

h2 {
  color:#002;
  font-size:1.2rem;
  font-weight:900;
}

h3 {
  color:#00562F;
  font-size:1.1rem;
  font-weight:600;
}

html {
  font-size:83.300003%;
}

label .req {
  color:#FF4279;
}

nav li {
  margin:.8rem 4px 0;
  min-height:1.3rem;
}

noscript {
  padding:.5rem;
}

.error {
  background:#ffd8e3;
  border:1px dotted red;
  padding:.5rem;
}

pre:focus,section:focus {
  outline:none;
}

pre:not(.msg) {
  -moz-user-focus:ignore;
  cursor:default;
  font-family:monospace!important;
}

pre:not(.msg),label {
  -moz-user-select:0;
  -ms-user-select:none;
  -webkit-user-select:none;
  user-select:none;
}

/** style balise article **/
article {
  /* background:#FFF none repeat scroll 0 0; */

  border-left:1px dashed #929292;
  border-right:1px dashed #929292;
  box-sizing:border-box;
  position:relative;
  z-index:3;
}

article .categ {
  left:0;
}

article .publie {
  right:0;
}

article .publie,article .categ {
  color:#CCC;
  position:absolute;
  top:-2rem;
}

article b.emo {
  background:#f7f7f7;
  font-weight:600;
  padding:.2rem;
}

article h1 {
  padding-bottom:1.4rem;
}

article h1 + main > h2:first-child {
  padding-top:0;
}

article h2 {
  color: #222;
  padding: .4rem;
  text-shadow: 0 1ch 1ch yellow;
}

article h3 {
  padding:.2rem;
}

article h3 + p,article h3 + .tgscroll p {
  padding-top:1rem;
}

article img {
  max-width:100%;
}

article li {
  margin-left:1rem;
  padding-left:1px;
}

article li,article p {
  font-weight:300;
}

article main {
  line-height:1.8em;
}

article p {
  padding-bottom:1rem;
}

article strong {
  border:1px solid #f4f4f4;
  font-weight:600;
  padding:.3rem;
}

article ul, ul {
  list-style:square;
}

article:after {
  bottom:-.5px;
}

article:before {
  top:-.5px;
}

article:before,article:after {
  border-top:1px dotted #fff;
  content:'';
  display:block;
  position:absolute;
  right:0;
  width:100%;
}

input ~ label[for$=art-tg] {
  display:block;
  text-align:right;
}
